* {
  box-sizing: border-box;
}

.form-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-item-control {
  display: inline-flex;
}

.form-item > label {
  font-size: 16px;
}

/* .form-control {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 16px;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
} */

.form-item.has-error > label,
.form-item.has-error > .form-item-feedback {
  color: rgb(255, 101, 101);
}

.form-item-feedback {
  font-size: 15px;
}

.form-item.has-error .form-control {
  border-color: rgb(255, 101, 101);
}

.form-item-config {
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  background-color: #4763960f;
  border-left: 5px solid #476396;
  padding: 15px;
  border-radius: 5px;
  margin: 5px 0;
}

.form-item-config-labels {
  display: flex;
  gap: 10px;
  width: 27%;
  max-width: 227px;
  min-width: 140px;
}

.form-item-config-labels > span {
  font-size: 15px;
  padding-top: 4px;
  color: #999;
}

.form-item-config-labels > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-item-config-labels > div > label {
  font-weight: bold;
}

.form-item-config-control {
  flex: 60;
}

.form-h-flex {
  display: flex;
  gap: 15px;
}

.form-h-flex > * {
  flex: 1;
}

.input-color-view {
  display: flex;
  width: 50px;
  height: 30px;
  border-radius: 5px;
}

input.color-input {
  height: 36px;
}

.input-list {
  display: flex;
  flex-direction: column;
  gap: 1px;
  width: 100%;
  margin-bottom: 10px;
}

.input-list-panel {
  gap: 15px;
}

.input-list-panel-item .card-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.input-list.notpanel > div{
  padding: 5px;
  background-color: #eaebeb;
  display: flex;
  width: 100%;
  display: flex;
  gap: 10px;
}


.input-list.notpanel > div > div{
  flex: 1;
  display: flex;
  gap: 3px;
}


.input-list.notpanel > div > span{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
}

.input-list.notpanel > div > button{
  min-height: 36px;
}


.input-list-1 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input-list-1 > div {
  flex: 1;
  width: 100%;
}

.input-list-1 > button {
  align-self: flex-start;
  justify-self: flex-start;
}

.input-img {
  min-width: 130px;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: rgb(248, 248, 248);
  cursor: pointer;
  position: relative;
  padding: 2px;
}

.input-img > img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
}

.input-img-ready {
  min-width: 50px;
}

.input-img-ready > span {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  font-size: 15px;
  z-index: 1000;
}