.container {
  max-width: 1340px;
  margin: 0 auto;
}

.layout1 {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.layout1-main {
  flex: 1;
}

#layout1-isChecked {
  display: none;
}

.layout1 .layout1-main-nav-container{
  height: 52px;
  background-color: #476396;
  color: #fff;
}

.layout1-top-nav > .container {
  display: flex;
  align-items: center;
  height: 70px;
}

.layout1-menu-btn {
  display: none;
}

.navlinks {
  list-style: none;
  display: flex;
  gap: 15px;
  height: 52px;
  margin: 0;
  padding: 0;
}

.navlinks > li {
  display: flex;
  align-items: center;
  padding: 0 15px;
  line-height: 16px;
  font-size: 16px;
}

.navlinks > li.active {
    background-color: rgba(0, 0, 0, 0.5);
}
.navlinks > li > a {
  color: #fff;
  text-decoration: none;
  height: 16px;
}

.layout1-avatar {
  display: flex;
  align-items: center;
  gap: 15px;
}

.layout1-avatar > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.layout1-avatar > img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.layout1-avatar svg {
  cursor: pointer;
}

.layout1-footer {
  background-color: #040c1b;
  color: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 15px;
}

.layout1-footer > .container {
  min-height: 40px;
  display: flex;
  align-items: center;
}

.layout1-main-nav {
  display: flex;
}

.navlinks-left {
  flex: 1;
}

.navlinks li {
  cursor: pointer;
}

.menulink {
  text-decoration: none;
  color: inherit;
}

.css-26l3qy-menu {
  min-width: max-content;
}