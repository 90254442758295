body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* 
html {
  overflow-y: scroll;
}
*/
.Loading-parent {
  position: relative;
}

.Loading-topRelative {
  position: absolute;
  inset: 0;
}

.Loading {
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
}

.Loading > * {
  display: flex;
}

.Loading-full-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-group-xs > .btn,
.btn-xs {
  padding: 4px;
  font-size: 12px;
  line-height: 12px;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: #333;
  text-decoration: none;
  background-color: rgb(239, 239, 239);
  display: flex;
  align-items: center;
  gap: 2px;
}

.btn-xs > * {
  font-size: 0.75rem;
}

.card-header {
  padding: 6px 16px;
}

.btn-group-sm > .btn,
.btn-sm {
  display: flex;
}

.form-control,
.form-select,
.input-group-text {
  border-radius: 0;
}

.row {
  row-gap: 15px;
}

.jstree-contextmenu {
  z-index: 1000000000;
}

.ck.ck-editor {
  color: initial;
}

.btn-action {
  height: 24px;
  min-width: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  background-color: #efefef;
  font-size: 14px;
  line-height: 14px;
  gap: 2px;
  border-radius: 0 !important;
  border: none !important;
}

.btn-action * {
  font-size: 18px;
}

.btn-action:hover * {
  color: var(--bs-primary);
}

.btn-check:focus + .btn-primary,
.btn-primary:focus,
.btn-check:active + .btn-primary,
.btn-primary:active,
.btn-check:hover + .btn-primary,
.btn-primary:hover {
  background-color: var(--primary-color-hover);
  border-color: var(--primary-color-hover);
}

.btn-check:focus + .btn-primary,
.btn-primary:focus,
.btn-check:active + .btn-primary,
.btn-primary:active {
  box-shadow: 0 0 0 0.25rem var(--primary-color-outline);
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: 0 0 0 0.25rem var(--primary-color-outline);
}

.form-control:focus,
.form-select:focus {
  box-shadow: 0 0 0 0.25rem var(--primary-color-outline);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--primary-color-hover);
  border-color: var(--primary-color-hover);
}

.td-actions,
.td-action {
  width: 1px;
}

.td-actions > div,
.td-action > div {
  display: flex;
  align-items: center;
  gap: 5px;
}

table.table {
  border: 1px solid #e5e5e5;
}

.table {
  --bs-table-striped-bg: #9b9b9b0d;
  --bs-table-hover-bg: #63adff0f;
}

.table > :not(:first-child) {
  border-top: none;
}

.btn-icon {
  display: flex;
  gap: 3px;
}

.flex-1 {
  flex: 1;
}

.page-item {
  cursor: pointer;
}

.form-select2 > span + div {
  border-radius: 5px;
  background-color: transparent;
}

.form-select2.is-invalid > div {
  border-color: #dc3545;
}

.MuiPaper-root {
  overflow-y: visible !important;
}

.btn-outline-secondary {
  border-color: #ced4da;
}

.dropdown-item {
  cursor: pointer;
}

.btn-select2-action {
  padding: 3px 6px;
  display: flex;
  align-items: center;
}

.input-group > .form-select2 {
  flex: 1;
}

.input-group > .btn {
  border-radius: 0;
}

.TableCRUDLoading-DialogTitle {
  background: #f9f9f9;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TableCRUDLoading-actions {
  padding: 20px !important;
  background: #f9f9f9;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.TableCRUDLoading-DialogContent {
  padding-top: 20px !important;
}

.nowrap {
  white-space: nowrap;
}

.wtabsui .MuiButtonBase-root {
  color: #fff;
}

.wtabsui .MuiButtonBase-root.Mui-selected {
  background-color: #fff;
}

.w-tabs {
  border: 2px solid;
  border-radius: 5px;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
}

.w-tabs-content {
  background-color: #fff;
  padding: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.w-box {
  padding: 15px;
}

.w-user-avatar {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.w-user-avatar > img,
.w-user-avatar > svg {
  aspect-ratio: 1;
  border-radius: 50%;
}

.w-user-name {
  font-weight: bold;
  font-size: 16px;
}

.w-user-v {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-self: center;
  flex: 1;
}

.w-TextEditor {
  border: none;
  border-radius: 10px;
  background-color: #ebebeb;
  border-top-left-radius: 0;
  padding: 10px;
  display: block;
  width: 100%;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  outline: none;
}

.w-TextEditor-container {
  width: 100%;
}

.w-TextEditor:focus {
  /* min-height: 150px; */
}

.w-flex-right {
  display: flex;
  justify-content: flex-end;
}

.form-control, .form-select, .input-group, .form-select2, .bg-input, .w-tabs > .MuiBox-root, .input-group-text, .input-group > .btn {
  background: #f9f9f9;
  background: -moz-linear-gradient(top,  #f9f9f9 0%, #e5e5e570 100%);
  background: -webkit-linear-gradient(top,  #f9f9f9 0%,#e5e5e570 100%);
  background: linear-gradient(to bottom,  #f9f9f9 0%,#e5e5e570 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#e5e5e570',GradientType=0 );
  border-radius: 5px;
  border-color: #ced4da70;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-color: #f9f9f9;
  background-color: -moz-linear-gradient(top,  #f9f9f9 0%, #e5e5e570 100%);
  background-color: -webkit-linear-gradient(top,  #f9f9f9 0%,#e5e5e570 100%);
  background-color: linear-gradient(to bottom,  #f9f9f9 0%,#e5e5e570 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#e5e5e570',GradientType=0 );
}

.input-img, .w-tabs {
  border-color: #ced4da70 !important;
}

.form-select2 > span + div {
  border-color: #ced4da70 !important;
}

.input-group .form-select2 > span + div {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-color: #ced4da70 !important;
}

.input-group .form-select2 + .btn-select2-action {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-color: #ced4da70;
}

.form-select2.is-invalid > span + div {
  border-color: #dc3545 !important;
  outline-color: #dc3545 !important;
}

.form-select2.is-invalid > span + div:focus {
  box-shadow: 0 0 0 1px #dc3545;
}

.wtabsui button {
  background-color: transparent;
  color: #000 !important;
}

.wtabsui button.Mui-selected {
  background-color: #fff;
  color: #000 !important;
}

.form-control,
.form-select,
.input-group-text, .input-group > button {
  border-radius: 5px;
}

.form-select2.is-invalid {
  border-color: red !important;
}