body, .layout1-main {
    background-color: #f8f8f8;
}
.footer-menu {
    display: flex;
    gap: 25px;
    align-items: center;
    justify-content: center;
    padding: 15px;
    background-color: #ffffff;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    box-shadow: 0 0 20px 0px #e5e5e5;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.FooterMenuItem, .FooterMenuItem:active, .FooterMenuItem:focus {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #afafbb;
    margin-top: -25px;
}

.FooterMenuItem .material-icons { 
    border: 10px solid transparent;
    margin-bottom: -10px;
    padding: 12px;
}

.FooterMenuItem-active .material-icons {
    border-radius: 50%;
    background: rgb(239,25,130);
    background: linear-gradient(0deg, rgba(239,25,130,1) 0%, rgba(247,138,41,1) 100%);
    color: #fff !important;
    border: 10px solid #fff;
}

.modalbottom.fade .modal-dialog {
    bottom: 0;
    position: absolute;
    width: 100%;
    padding: 0;
    margin: 0;
}

.modalbottom.fade .modal-dialog {
    transform: translate(0,115px);
}

.modalbottom.show .modal-dialog {
    transform: none;
}

.modalbottom .modal-content {
    border: none;
    border-radius: 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 20px;
}