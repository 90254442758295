.ticketheader {
  margin: -9px -9px 0 -9px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: center;
  font-size: 24px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 5px 10px;
  position: relative;
  min-height: 80px;
}

.ticketheader-doctype {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ticketheader > .ticketheader-doctotal {
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-end;
  justify-content: center;
  min-width: 114px;
}

.ticketheader-doctotal > small {
  font-size: 13px;
}

.ticketheader > .ticketheader-doctotal .ticket-prix {
  font-size: 28px;
}

.tickettabs {
  display: flex;
  position: relative;
  margin-top: -46px;
  gap: 5px;
}

.tickettabs > div {
  background-color: rgb(119, 119, 119);
  color: #000;
  padding: 5px 10px;
  height: 36px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  line-height: 12px;
  align-items: center;
  gap: 4px;
}

.tickettabs > div.active {
  background-color: #fff;
  color: #000;
}

.tickettabs-nonpaye {
  background-color: rgba(218, 10, 10, 0.509) !important;
}

.tickettabs-paye {
  background-color: #36d74680 !important;
}

.tickettabs-nonpaye.active {
  color: red !important;
  background-color: #fff !important;
}

.tickettabs-paye.active {
  background-color: #fff !important;
}

.ticket-prix {
  display: inline-flex;
  gap: 10px;
}

.ticket-prix > small {
  font-size: 50%;
}

.ticket-actions {
  display: flex;
  gap: 10px;
  zoom: 0.8;
}

.ticket-actions > button {
  font-size: 16px;
  line-height: 31px;
}

.PrixInput {
  width: 100%;
  border: none;
  background: none;
  text-align: right;
}

.tickettablelist::-webkit-scrollbar {
  width: 10px !important;
}

.tickettablelist::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.tickettablelist::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.tickettablelist {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.tickettablelist > div {
  border: 1px solid #ccc;
}

.ticketarticles {
  /* padding: 10px; */
}

.SectionTitleSep {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.SectionTitleSep-line {
  display: inline-block;
  flex: 1;
  height: 1px;
  background-color: #00000040;
}

.tr-animation th,
.tr-animation td {
  -webkit-animation-name: animation;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-play-state: running;
  animation-name: animation;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

@-webkit-keyframes animation {
  0% {
    background-color: yellow;
  }
  50% {
    background-color: green;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes animation {
  0% {
    background-color: yellow;
  }
  50% {
    background-color: green;
  }
  100% {
    background-color: transparent;
  }
}

.btn-info-line {
  display: flex;
  align-items: flex-start;
  gap: 3px;
  margin: -3px 0 -3px -16px;
}

.eye-modal {
  display: flex;
  align-items: center;
  gap: 5px;
}

.eye-modal > button {
  padding: 0;
  margin: 0;
}

.ticket-line-title > small {
  color: rgb(16, 56, 203);
}

.ck.ck-editor {
  width: 100%;
}

.ticket-total-pay {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.ticket-total-pay > small{
  font-size: 0.8em;
}
