.atom-item-container {
  border: 1px dashed transparent;
  position: relative;
}

.atom-item-container > .atom-item-menu {
  position: absolute;
  background: #905d00;
  padding: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  left: 2px;
  top: -25px;
  height: 25px;
  cursor: pointer;
  display: none;
  align-items: center;
  padding: 0 6px;
  z-index: 10;
}

.atom-item-container:hover {
  border: 1px dashed orange;
}
.atom-item-container:hover > .atom-item-menu {
  display: flex !important;
}

.atom-item-menu-btn {
  height: 25px;
  font-size: 14px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.atom-item-menu-btn > button {
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
}
.atom-item-menu-list {
  width: auto;
  position: absolute;
  z-index: 100;
  background: #8a5900;
  border: 1px solid #8a5900;
  padding: 2px;
  top: 24px;
  left: 0;
  display: none;
}
.atom-item-menu-list > button {
  margin: 2px;
  display: flex;
  padding: 2px;
}
.atom-item-menu-btn:hover + .atom-item-menu-list,
.atom-item-menu-list:hover {
  display: flex;
}
.atom-list {
  display: flex;
  flex-direction: column-reverse;
}
.atom-list-bottom {
  display: none;
  border-top: none;
  border-right: 1px dashed transparent;
  border-bottom: 1px dashed transparent;
  border-left: 1px dashed transparent;
  padding: 3px;
}
.atom-list:hover > .atom-list-bottom,
.atom-list-empty > .atom-list-bottom {
  display: flex;
}
.atom-list-content {
  border: 1px dashed transparent;
}

.atom-list-empty > .atom-list-bottom {
  border: 1px dashed rgba(0, 0, 0, 0.5);
}

.atom-list-bottom:hover {
  border-top: none;
  border-right: 1px dashed blueviolet;
  border-bottom: 1px dashed blueviolet;
  border-left: 1px dashed blueviolet;
  background-color: rgba(137, 43, 226, 0.123);
}
.atom-list-bottom:hover + .atom-list-content {
  border: 1px dashed blueviolet;
}

.atom-list-empty > .atom-list-bottom:hover {
  border-top: 1px dashed blueviolet;
}

.atom-list-empty > .atom-list-bottom:hover + .atom-list-content {
  border: 1px dashed transparent;
}

.atom-dialog {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.atom-dialog-content {
  background: #fff;
  width: 80%;
  max-width: 600px;
  min-height: 350px;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  -webkit-box-shadow: 2px 2px 10px 0 #4f4a4a;
  box-shadow: 2px 2px 10px 0 #4f4a4a;
  position: relative;
}
.atom-dialog-content > * {
  width: 100%;
}
.atom-dialog-body {
  flex: 1;
  padding: 15px;
}
.atom-dialog-header,
.atom-dialog-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: #efefef;
}

.btnbtndialog {
  border: none;
  background: transparent;
}

.AtomModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.AtomModal.AtomModal-visible {
  visibility: visible;
  opacity: 1;
}

.AtomModalDialog {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 80%;
  max-width: 700px;
  transform: translateY(-200px);
  opacity: 0;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  color: initial !important;
}

.AtomModal.AtomModal-visible > .AtomModalDialog {
  transform: translateY(0);
  color: initial !important;
  opacity: 1;
}

.AtomModal-body {
  min-height: 250px;
  padding: 15px;
  max-height: 65vh;
  overflow: auto;
}

.AtomModal-header,
.AtomModal-footer {
  padding: 15px;
  background-color: rgb(235, 235, 235);
}

.AtomModal-footer {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: flex-end;
}
.atombtn {
  border: 1px solid #d3d3d3;
  background-color: #d3d3d3;
  color: #000;
  display: inline-block;
  min-height: 30px;
  align-items: center;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.atombtn:active {
  border: 1px solid #3d3d3d;
  background-color: #3d3d3d;
  color: #ccc;
}

.atombtn.atomprimary {
  border: 1px solid #0051ff;
  background-color: #0051ff;
  color: #fff;
}

.atombtn.atomprimary:active {
  border: 1px solid #001b55;
  background-color: #001b55;
  color: rgb(136, 136, 136);
}

.atom-item-menu-list > button {
  font-size: 14px;
  line-height: 14px;
  height: 20px;
  width: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #f9f9f9;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.atom-item-menu-list > button > .material-icons,
.atom-item-menu-btn > button > .material-icons {
  font-size: 14px;
  line-height: 14px;
}

.atom-item-menu-btn > button > .material-icons {
  color: #fff;
}

.AtomListComToAdd {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.AtomListComToAdd > button {
  min-width: 120px;
}

.atom-list-bottom {
  display: flex;
  gap: 4px;
  font-size: 12px;
}
.atom-list-bottom > button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}
.atom-list-bottom > button > .material-icons {
  font-size: 14px;
  line-height: 14px;
  width: 14px;
  height: 14px;
}

.AtomCols {
  display: flex;
}

table.bordered,
table.bordered th,
table.bordered td {
  border: 1px solid #ccc;
  border-collapse: collapse;
}
table.bordered {
  margin: 15px 0;
}
table.bordered th,
table.bordered td {
  padding: 4px;
}

.nowrap {
  white-space: nowrap;
}